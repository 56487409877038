import React, { useState } from "react";
import { Modal } from "../../modal/Modal";
import { Character, formatRoll } from "@ai-dm/utils";

interface CharacterSheetProps {
  character: Character;
  updateCharacter: (key: keyof Character, value: any) => void;
}

export const CharacterSheet: React.FC<CharacterSheetProps> = ({
  character,
  updateCharacter,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [expandedItem, setExpandedItem] = useState<string | null>(null);
  const [expandedSubItem, setExpandedSubItem] = useState<string | null>(null);

  const toggleItem = (itemName: string) => {
    if (expandedItem === itemName) {
      setExpandedItem(null);
    } else {
      setExpandedItem(itemName);
    }
  };

  const toggleSubItem = (subItemName: string) => {
    if (expandedSubItem === subItemName) {
      setExpandedSubItem(null);
    } else {
      setExpandedSubItem(subItemName);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const formatStat = (stat: number) => {
    return stat >= 0 ? `+${stat}` : stat;
  };

  const statWidget = (statValue: number, statName: string) => {
    return (
      <div className="text-center">
        <p>{statName.toUpperCase()}</p>
        <p>{formatStat(statValue)}</p>
      </div>
    );
  };

  return (
    <div className="side-panel">
      <div className="flex justify-between items-center mb-2 underlined-container">
        <h2>{character.name}</h2>
        <span className="text-base bg-tertiary px-2 py-1 rounded">Level 1</span>
        {character.character_points.total - character.character_points.used >
          0 && (
          <button
            className="button text-base px-2 py-1 bg-tertiary hover:bg-light"
            onClick={() => setIsModalOpen(true)}
          >
            Level Up
          </button>
        )}
      </div>

      <div className="grid grid-cols-4 gap-2">
        <p className="col-span-2">
          {character.race} {character.background}
        </p>
        <div className="flex justify-between items-center bg-tertiary p-1 rounded">
          <span>HP</span>
          <span>7/7</span>
        </div>

        <div className="flex justify-between items-center bg-tertiary p-1 rounded">
          <span>AC</span>
          <span>15</span>
        </div>
      </div>
      <h3>Stats</h3>
      <div className="flex justify-between">
        {Object.entries(character.stats).map(([statName, statValue]) =>
          statWidget(statValue, statName)
        )}
      </div>
      <button
        onClick={() =>
          console.log("Character", JSON.stringify(character, null, 2))
        }
      >
        Test
      </button>
      <h3>Actions</h3>
      <div>
        <ul className="space-y-2">
          {character.actions.map((action, index) => (
            <li
              key={index}
              className="border border-gray-700 rounded bg-secondary"
            >
              <button
                className="flex justify-between items-center w-full p-2 text-left transition-colors"
                onClick={() => toggleItem(action.name)}
              >
                <span className="flex items-center gap-2">{action.name}</span>
              </button>

              {expandedItem === action.name && (
                <div className="px-2 pb-2">
                  <em className="block text-gray-400 text-base leading-tight">
                    {action.description}
                  </em>
                  <div className="mt-2 space-y-0.5 text-base">
                    <p>
                      <b>Range:</b> {action.range}
                    </p>
                    {action.abilityCheck &&
                      action.abilityCheck.stat.length > 0 && (
                        <p>
                          <b>Ability Check:</b>{" "}
                          {action.abilityCheck.stat.join(", ")}
                        </p>
                      )}
                    {action.damage && (
                      <p>
                        <b>Damage:</b> {formatRoll(action.damage)}
                      </p>
                    )}
                    {action.effects && (
                      <p>
                        <b>Effects:</b>
                        {action.effects.map((effect, index) => (
                          <li
                            key={index}
                            className="border border-gray-700 rounded bg-secondary"
                          >
                            <button
                              className="flex justify-between items-center w-full p-2 text-left transition-colors"
                              onClick={() => toggleSubItem(effect.name)}
                            >
                              <span className="flex items-center gap-2">
                                {effect.name}
                              </span>
                            </button>

                            {expandedSubItem === effect.name && (
                              <div className="px-2 pb-2">
                                <em className="block text-gray-400 text-base leading-tight">
                                  {effect.description}
                                </em>
                                <div className="mt-2 space-y-0.5 text-base">
                                  {effect.duration && (
                                    <p>
                                      <b>Duration:</b> {effect.duration}
                                    </p>
                                  )}
                                </div>
                              </div>
                            )}
                          </li>
                        ))}
                      </p>
                    )}
                  </div>
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
      <h3>Conditions</h3>
      <ul className="space-y-2">
        {character.conditions.map((condition, index) => (
          <li
            key={index}
            className="border border-gray-700 rounded bg-secondary"
          >
            <button
              className="flex justify-between items-center w-full p-2 text-left transition-colors"
              onClick={() => toggleItem(condition.name)}
            >
              <span className="flex items-center gap-2">{condition.name}</span>
            </button>

            {expandedItem === condition.name && (
              <div className="px-2 pb-2">
                <em className="block text-gray-400 text-base leading-tight">
                  {condition.description}
                </em>
                <div className="mt-2 space-y-0.5 text-base">
                  {condition.timed && (
                    <p>
                      <b>Duration:</b> {condition.timed.timeLeft}
                      {"/"}
                      {condition.timed.duration}
                    </p>
                  )}
                </div>
              </div>
            )}
          </li>
        ))}
      </ul>
      <h3>Skills</h3>
      <ul>
        {character.skills.map((skill, index) => (
          <li key={index}>{skill}</li>
        ))}
      </ul>
      <h3>Languages</h3>
      <ul className="list-none pl-0">
        {character.languages.map((language, index) => (
          <li key={index}>{language}</li>
        ))}
      </ul>
      <h3>Abilities</h3>
      <ul>
        {character.abilities.map((ability, index) => (
          <li key={index}>
            <strong>{ability.name}:</strong> {ability.description}
          </li>
        ))}
      </ul>
      <h3>Description</h3>
      <p>{character.description}</p>
      {isModalOpen ? (
        <Modal
          levelUpModalProps={{
            character: character,
            updateCharacter: updateCharacter,
          }}
          onClose={closeModal}
        ></Modal>
      ) : undefined}
    </div>
  );
};
