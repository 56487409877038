"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatRoll = exports.isNumber = exports.isDice = exports.D6 = exports.D20 = void 0;
exports.D20 = {
    sides: 20,
};
exports.D6 = {
    sides: 6,
};
const isDice = (item) => {
    return typeof item === "object" && item !== null && "sides" in item;
};
exports.isDice = isDice;
const isNumber = (item) => {
    return typeof item === "number";
};
exports.isNumber = isNumber;
const formatRoll = (roll) => {
    // Sort roll items: dice by sides in descending order, then numbers in descending order
    const sortedRoll = [...roll].sort((a, b) => {
        if ((0, exports.isNumber)(a) && (0, exports.isNumber)(b)) {
            return b - a; // Sort numbers descending
        }
        else if ((0, exports.isDice)(a) && (0, exports.isDice)(b)) {
            return b.sides - a.sides; // Sort dice descending by sides
        }
        else if ((0, exports.isNumber)(a)) {
            return 1; // Numbers go after dice
        }
        else {
            return -1; // Dice go before numbers
        }
    });
    // Format each part
    return sortedRoll
        .map((item) => {
        if ((0, exports.isNumber)(item)) {
            return item >= 0 ? `${item}` : item.toString();
        }
        else if ((0, exports.isDice)(item)) {
            const diceString = `d${item.sides}`;
            if (item.advantage) {
                return `adv(${diceString})`;
            }
            if (item.disadvantage) {
                return `dis(${diceString})`;
            }
            return diceString;
        }
    })
        .join("+")
        .replace(/\+-/g, "-"); // Clean up for cases like "+ -2"
};
exports.formatRoll = formatRoll;
