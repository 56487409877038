import { POI } from "@ai-dm/utils";
import { GOBLIN, HUMAN_COMMONER } from "./creatures";

export const DELPHIAN_TOMB: POI = {
  name: "Delphian Tomb",
  description:
    "An ancient tomb of a dead hero of the Delphian paladin order. It has been taken over by bandit goblins preying on the nearby town.",
  rooms: [
    {
      name: "Tomb Entrance",
      description:
        "A once-grand entrance hall to an ancient tomb, now defiled with goblin markings and refuse. Delicate stone carvings of knights in ceremonial armor line the walls, though some have been crudely vandalized. Torchlight flickers from deeper within.",
      exits: [
        {
          toRoom: "Guard Post",
          description:
            "A dark corridor leads deeper into the tomb, the sound of snoring echoes faintly.",
        },
      ],
      creatures: [],
      items: [],
      effect: [],
    },
    {
      name: "Guard Post",
      description:
        "A small chamber that appears to serve as a guard post. A crude wooden table holds the remains of a meal, and a goblin dozes in a chair, softly snoring.",
      exits: [
        {
          toRoom: "Tomb Entrance",
          description:
            "The entrance hall lies back the way you came. A dark corridor leading to the surface.",
        },
        {
          toRoom: "Goblin Bivouac",
          description:
            "A wider passage leads to an area with more light and the sound of guttural voices.",
        },
      ],
      creatures: [{ ...GOBLIN }],
      items: [],
      effect: [],
    },
    {
      name: "Goblin Bivouac",
      description:
        "A larger chamber converted into living quarters. Bedrolls and personal belongings are scattered about. In one corner stands an iron cage containing a frightened commoner. On the far wall, carved in ancient script, is a riddle: 'I am not alive, but I grow; I don't have lungs, but I need air; I don't have a mouth, but water kills me. What am I?'",
      exits: [
        {
          toRoom: "Guard Post",
          description: "The guard post lies back through the northern passage.",
        },
        {
          toRoom: "True Tomb",
          description:
            "A section of the wall slides away silently when the word 'Fire' is spoken, revealing a hidden passage.",
        },
      ],
      creatures: [
        { ...GOBLIN },
        { ...GOBLIN },
        { ...GOBLIN },
        { ...GOBLIN },
        { ...HUMAN_COMMONER },
      ],
      items: [],
      effect: [],
    },
    {
      name: "True Tomb",
      description:
        "A pristine burial chamber untouched by the goblins. A stone sarcophagus lies in the center, its lid carved with the image of a noble knight. Within lies a skeleton in ceremonial armor, clutching a magnificent sword that gleams with an inner light.",
      exits: [
        {
          toRoom: "Goblin Bivouac",
          description:
            "The secret passage leads back to the goblin-occupied chamber.",
        },
      ],
      creatures: [],
      items: [],
      effect: [],
    },
  ],
};
