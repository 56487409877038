import { Creature, POI, Room, World } from "@ai-dm/utils";
import React, { useState } from "react";

interface DebugTabProps {
  world: World;
}

export const DebugTab: React.FC<DebugTabProps> = ({ world }) => {
  const { currentPOI, currentHex, currentRoom } = world;

  return (
    <div className="side-panel p-4 text-sm">
      <div className="mb-4">
        <p className="font-semibold">
          Current Hex Coords:{" "}
          <span className="font-normal">
            x: {currentHex.x}, y: {currentHex.y}
          </span>
        </p>
      </div>

      {/* If there is a current POI, render it */}
      {currentPOI && <PoiDetails poi={currentPOI} currentRoom={currentRoom} />}
    </div>
  );
};

/**
 * POI component that shows the list of rooms (expandable)
 */
const PoiDetails: React.FC<{ poi: POI; currentRoom: string | undefined }> = ({
  poi,
  currentRoom,
}) => {
  // If you want to collapse the entire POI's description, you could add a toggle here,
  // but as described, we always show the name/description, then handle rooms individually.
  return (
    <div className="border border-gray-300 p-2 rounded mb-4">
      <div className="mb-2">
        <h3 className="text-md font-semibold">{poi.name}</h3>
        <p className="text-sm">{poi.description}</p>
      </div>
      <div>
        {poi.rooms.map((room, idx) => (
          <RoomDetails key={idx} room={room} currentRoom={currentRoom} />
        ))}
      </div>
    </div>
  );
};

/**
 * Room component that can be collapsed/expanded
 */
const RoomDetails: React.FC<{
  room: Room;
  currentRoom: string | undefined;
}> = ({ room, currentRoom }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const borderColor =
    room.name === currentRoom ? "border-white" : "border-gray-600";

  return (
    <div className={`border ${borderColor} p-2 rounded mb-2`}>
      {/* Header (with expand/collapse button) */}
      <div className="flex justify-between items-center cursor-pointer">
        <h4 className="font-semibold">{room.name}</h4>
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className="text-gray-600 hover:text-white focus:outline-none"
        >
          {isExpanded ? "-" : "+"}
        </button>
      </div>

      {/* Body only appears if expanded */}
      {isExpanded && (
        <div className="mt-2 text-sm">
          <p className="mb-2">{room.description}</p>
          <div className="mb-2">
            <strong>Exits:</strong>
            <ul className="list-disc list-inside ml-4">
              {room.exits.map((exit, i) => (
                <li key={i}>
                  <strong>To:</strong> {exit.toRoom} – {exit.description}
                </li>
              ))}
            </ul>
          </div>

          {/* Creatures */}
          {room.creatures && (
            <div>
              <strong>Creatures:</strong>
              {room.creatures.length === 0 ? (
                <p className="text-gray-600">No creatures here.</p>
              ) : (
                room.creatures.map((creature, idx) => (
                  <CreatureDetails key={idx} creature={creature} />
                ))
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

/**
 * Creature component that can be collapsed/expanded
 */
const CreatureDetails: React.FC<{ creature: Creature }> = ({ creature }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="border border-gray-200 rounded p-2 mt-2">
      <div className="flex justify-between items-center cursor-pointer">
        <span className="font-semibold">{creature.creatureName}</span>
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className="text-gray-600 hover:text-white focus:outline-none"
        >
          {isExpanded ? "-" : "+"}
        </button>
      </div>

      {isExpanded && (
        <div className="mt-2 text-sm">
          <p className="italic">{creature.shortDescription}</p>
          <p>{creature.description}</p>

          <div className="mt-2">
            <strong>HP:</strong> {creature.hp}/{creature.maxhp} –{" "}
            <strong>AC:</strong> {creature.ac}
          </div>
          <div>
            <strong>Size:</strong> {creature.size}
          </div>
          <div>
            <strong>Conditions:</strong>{" "}
            {creature.conditions.length
              ? creature.conditions.join(", ")
              : "None"}
          </div>

          {/* Stats */}
          <div className="mt-2">
            <strong>Stats:</strong>
            <ul className="list-disc list-inside ml-4">
              {Object.entries(creature.stats).map(([stat, value]) => (
                <li key={stat}>
                  {stat}: {value}
                </li>
              ))}
            </ul>
          </div>

          {/* Skills */}
          <div className="mt-2">
            <strong>Skills:</strong>{" "}
            {creature.skills.length ? creature.skills.join(", ") : "None"}
          </div>

          {/* Senses */}
          <div>
            <strong>Senses:</strong>{" "}
            {creature.senses.length ? creature.senses.join(", ") : "None"}
          </div>

          {/* Languages */}
          <div>
            <strong>Languages:</strong>{" "}
            {creature.languages.length ? creature.languages.join(", ") : "None"}
          </div>

          {/* CR */}
          <div>
            <strong>CR:</strong> {creature.cr}
          </div>

          {/* Abilities */}
          <div className="mt-2">
            <strong>Abilities:</strong>
            {creature.abilities.length ? (
              <ul className="list-disc list-inside ml-4">
                {creature.abilities.map((ability, i) => (
                  <li key={i}>
                    <strong>{ability.name}:</strong> {ability.description}
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-gray-600">No abilities</p>
            )}
          </div>

          {/* Attacks */}
          <div className="mt-2">
            <strong>Attacks:</strong>
            {creature.actions.length ? (
              <ul className="list-disc list-inside ml-4">
                {creature.actions.map((attack, i) => (
                  <li key={i}>
                    <strong>{attack.name}:</strong> {attack.description}{" "}
                    (Damage: {JSON.stringify(attack.damage)})
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-gray-600">No attacks</p>
            )}
          </div>

          {/* Info */}
          {creature.info && (
            <div className="mt-2">
              <strong>Info:</strong> {creature.info}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
