import { CharacterBackground } from "../data/classes";
import {
  Armor,
  Weapon,
  Item,
  Action,
  D20,
  isArmor,
  Condition,
} from "@ai-dm/utils";
import { Character } from "@ai-dm/utils";
import { StatValue } from "../components/terminal-entry/stat-allocation/StatAllocation";
import { isPresent } from "./utility";

export const setCharacterBackground = (
  character: Character,
  charClass: CharacterBackground
): Character => ({
  ...character,
  background: charClass.name,
  hp: {
    ...character.hp,
    perlevel: 5,
  },
  skills: charClass.skills,
});

export const setCharacterStats = (
  character: Character,
  stats: StatValue[],
  inventory: Item[]
): Character => {
  const newCharacter = { ...character };
  newCharacter.stats = {
    str: stats.find((stat) => stat.name === "str")?.value || 0,
    dex: stats.find((stat) => stat.name === "dex")?.value || 0,
    cha: stats.find((stat) => stat.name === "cha")?.value || 0,
    con: stats.find((stat) => stat.name === "con")?.value || 0,
    wis: stats.find((stat) => stat.name === "wis")?.value || 0,
  };

  newCharacter.hp = updateMaxHp(newCharacter);
  console.log("HP", newCharacter.hp);
  newCharacter.ac = setCharacterAC(newCharacter, inventory);
  newCharacter.actions = setCharacterActions(newCharacter, inventory);
  newCharacter.conditions = setCharacterConditions(newCharacter, inventory);

  return newCharacter;
};

export const updateMaxHp = (
  character: Character
): { current: number; max: number; perlevel: number } => {
  const newMaxHp =
    character.hp.perlevel * character.level + character.stats.con;
  const hpIncrease = newMaxHp - character.hp.max;
  return {
    current: Math.min(character.hp.current + hpIncrease, newMaxHp),
    max: newMaxHp,
    perlevel: character.hp.perlevel,
  };
};

export const setCharacterAC = (
  character: Character,
  inventory: Item[]
): number => {
  const armorItems = inventory.filter(
    (item): item is Armor => isArmor(item) && item.equipped
  );

  let baseAC = 10;
  let hasDexMod = true;
  let hasHalfDexMod = false;

  for (const armor of armorItems) {
    baseAC += armor.ac;
    if (armor.modifier === "None") {
      hasDexMod = false;
      hasHalfDexMod = false;
      break;
    } else if (armor.modifier === "Dex/2") {
      hasHalfDexMod = true;
    }
  }

  if (hasDexMod) {
    baseAC += character.stats.dex;
  } else if (hasHalfDexMod) {
    baseAC += Math.floor(character.stats.dex / 2);
  }

  return baseAC;
};

const setCharacterActions = (
  character: Character,
  inventory: Item[]
): Action[] => {
  const actions: Action[] = [];

  actions.push({
    name: "Unarmed Strike",
    description:
      "A basic melee attack using your fists or body. Requires a d20 strength check.",
    abilityCheck: {
      stat: ["str"],
    },
    damage: [Math.max(1 + character.stats.str, 1)],
    range: "Close",
  });

  for (const ability of character.abilities) {
    if (isPresent(ability.actions)) {
      for (const action of ability.actions) {
        actions.push(action);
      }
    }
  }

  for (const item of inventory) {
    if (isPresent(item.actions)) {
      for (const action of item.actions) {
        actions.push(action);
      }
    }
  }

  return actions;
};

const setCharacterConditions = (
  character: Character,
  inventory: Item[]
): Condition[] => {
  const conditions: Condition[] = [];

  for (const ability of character.abilities) {
    if (isPresent(ability.conditions)) {
      for (const condition of ability.conditions) {
        conditions.push(condition);
      }
    }
  }

  for (const item of inventory) {
    if (isPresent(item.conditions)) {
      if (item.conditions.mustBeEquipped) {
        if (item.equipped) {
          for (const condition of item.conditions.conditionList) {
            conditions.push(condition);
          }
        }
      } else {
        for (const condition of item.conditions.conditionList) {
          conditions.push(condition);
        }
      }
    }
  }

  return conditions;
};

export const initializeCharacter = (): Character => ({
  name: "Unknown",
  background: "",
  gender: "Male",
  description: "",
  ac: 10,
  stats: {
    str: 0,
    dex: 0,
    cha: 0,
    con: 0,
    wis: 0,
  },
  race: "Human",
  skills: [],
  level: 1,
  character_points: {
    used: 0,
    total: 0,
  },
  hp: {
    current: 0,
    max: 0,
    perlevel: 0,
  },
  languages: ["Common"],
  actions: [],
  abilities: [],
  conditions: [],
});

export const DEFAULT_CHARACTER: Character = {
  name: "Adrian",
  background: "City Guard",
  gender: "Male",
  description:
    "Adrian is a handsome, tan-skinned male standing 6'1\" tall, with blonde hair and a strong jaw accentuated by a prominent broken nose. He possesses a lean and muscular physique, highlighting his athleticism.",
  ac: 15,
  stats: {
    str: 4,
    dex: 0,
    cha: 2,
    con: 2,
    wis: -2,
  },
  race: "Human",
  skills: ["Investigation", "Intimidation", "Local Laws"],
  level: 1,
  character_points: {
    used: 0,
    total: 0,
  },
  hp: {
    current: 7,
    max: 7,
    perlevel: 5,
  },
  languages: ["Common"],
  actions: [
    {
      name: "Unarmed Strike",
      description: "A basic melee attack using your fists or body.",
      abilityCheck: {
        stat: ["str"],
      },
      damage: [5],
      range: "Close",
    },
    {
      name: "Longsword Strike",
      description: "A powerful slash or thrust with your longsword.",
      range: "Close",
      abilityCheck: {
        stat: ["str"],
      },
      damage: [
        {
          sides: 8,
        },
        {
          sides: 20,
          disadvantage: true,
        },
        -1,
      ],
      twoHands: false,
    },
    {
      name: "Shield Bash",
      description:
        "A powerful thrust with your shield that can knock enemies back.",
      range: "Close",
      abilityCheck: {
        stat: ["str"],
      },
      damage: [
        {
          sides: 4,
        },
      ],
    },
  ],
  abilities: [],
  conditions: [
    {
      name: "Loud Movement",
      description:
        "The loud ring of chainmail links imposes disadvantage on all stealth checks.",
    },
    {
      name: "Heavy",
      description:
        "The weight and bulk of the Chainmail Shirt imposes disadvantage on all attempts to swim.",
    },
  ],
};
