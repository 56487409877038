import React, { useState } from "react";

interface Tile {
  biome: {
    color: string;
    name: string;
    temperature: string;
  };
  pointsOfInterest?: { title: string }[];
}

interface MapData {
  map: Tile[][];
}

export const WorldMap: React.FC = () => {
  const [map, setMap] = useState<MapData | null>(null);
  const [hoveredTile, setHoveredTile] = useState<Tile | null>(null);
  const [loading, setLoading] = useState(false);

  const fetchMap = async () => {
    setLoading(true);
    try {
      const response = await fetch("http://localhost:3000/generate-map", {
        method: "POST",
      });
      if (!response.ok) {
        throw new Error("Failed to fetch map data.");
      }
      const data = await response.json();
      setMap(data);
    } catch (error) {
      console.error("Error fetching map:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="side-panel flex flex-col items-center justify-center h-full p-4 bg-secondary text-text">
      <button
        onClick={fetchMap}
        className="bg-primary text-text py-2 px-4 rounded-lg hover:bg-tertiary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
        disabled={loading}
      >
        {loading ? "Generating..." : "Generate Map"}
      </button>
      {map && (
        <div className="flex flex-col items-center mt-4">
          {map.map.map((row, y) => (
            <div key={y} className="flex">
              {row.map((tile, x) => (
                <div
                  key={x}
                  className="w-4 h-4"
                  style={{ backgroundColor: tile.biome.color }}
                  onMouseEnter={() => setHoveredTile(tile)}
                  onMouseLeave={() => setHoveredTile(null)}
                ></div>
              ))}
            </div>
          ))}
        </div>
      )}
      {hoveredTile && (
        <div className="mt-4 p-4 bg-tertiary rounded">
          <h3 className="text-h3">Tile Info</h3>
          <p>Biome: {hoveredTile.biome.name}</p>
          <p>Temperature: {hoveredTile.biome.temperature}</p>
          {hoveredTile.pointsOfInterest &&
            hoveredTile.pointsOfInterest.length > 0 && (
              <p>
                Points of Interest:{" "}
                {hoveredTile.pointsOfInterest
                  .map((poi) => poi.title)
                  .join(", ")}
              </p>
            )}
        </div>
      )}
    </div>
  );
};
