import React, { useState } from "react";
import { Modal } from "../../modal/Modal";
import { CLASSES } from "../../../data/classes";

export interface ModalSelectorProps {
  type: "classSelection" | "backgroundSelection";
  options: string[];
  onSelect: (selection: string) => void;
  selectedOption: string | null;
}

export const ModalSelector: React.FC<ModalSelectorProps> = ({
  type,
  options,
  onSelect,
  selectedOption,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentOption, setCurrentOption] = useState<string | null>(null);

  const handleOptionClick = (option: string) => {
    setCurrentOption(option);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentOption(null);
  };

  const selectOption = () => {
    if (currentOption) {
      onSelect(currentOption);
    }
    setIsModalOpen(false);
  };

  return (
    <div>
      {!selectedOption && (
        <div className="flex flex-wrap">
          {options.map((option) => (
            <button
              key={option}
              className="button"
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </button>
          ))}
        </div>
      )}
      {isModalOpen && currentOption && (
        <Modal
          classModalProps={
            type === "classSelection"
              ? (() => {
                  const characterClass = CLASSES.find(
                    (cls) => cls.name === currentOption
                  );
                  return characterClass
                    ? {
                        characterClass,
                      }
                    : undefined;
                })()
              : undefined
          }
          onClose={closeModal}
          onSelect={selectOption}
        />
      )}
    </div>
  );
};
