import React, { useState } from "react";
import DOMPurify from "dompurify";
import {
  StatValue,
  StatAllocation,
  StatAllocationProps,
} from "./stat-allocation/StatAllocation";
import { DiceRoll, DiceRollProps } from "./dice-roll/DiceRoll";
import { isPresent } from "../../utils/utility";
import {
  ModalSelector,
  ModalSelectorProps,
} from "./modal-selector/ModalSelector";

export interface TerminalEntryProps {
  content: string;
  role: "user" | "assistant";
  type?:
    | "classSelection"
    | "backgroundSelection"
    | "statAllocation"
    | "diceRoll";
  modalSelectorProps?: ModalSelectorProps;
  statAllocationProps?: StatAllocationProps;
  diceRollProps?: DiceRollProps;
}

// Splits content into chunks of normal text vs. DM action text
function parseDMActionSegments(text: string) {
  // Regex looks for %DM_ACTION_START% <stuff> %DM_ACTION_END%
  const regex = /%DM_ACTION_START%(.*?)%DM_ACTION_END%/g;
  const segments: { type: "text" | "dmAction"; content: string }[] = [];
  let lastIndex = 0;
  let match;

  // Iterate all matches
  while ((match = regex.exec(text)) !== null) {
    // Everything before the match is normal text
    if (match.index > lastIndex) {
      segments.push({
        type: "text",
        content: text.slice(lastIndex, match.index),
      });
    }

    // Group 1 is the stuff inside the tokens
    segments.push({ type: "dmAction", content: match[1].trim() });

    // Update lastIndex to the end of this match
    lastIndex = match.index + match[0].length;
  }

  // If anything remains after last match, treat it as normal text
  if (lastIndex < text.length) {
    segments.push({
      type: "text",
      content: text.slice(lastIndex),
    });
  }

  return segments;
}

export const TerminalEntry: React.FC<TerminalEntryProps> = ({
  content: text,
  role,
  type,
  modalSelectorProps,
  statAllocationProps,
  diceRollProps,
}) => {
  const formatText = (inputText: string, role: "user" | "assistant") => {
    const formattedText = role === "user" ? `&gt; ${inputText}` : inputText;
    return DOMPurify.sanitize(formattedText, {
      ALLOWED_TAGS: ["b", "i", "em", "strong"],
    });
  };

  return (
    <div
      className={`p-2 text-xl whitespace-pre-wrap rounded mb-2 ${
        role === "user" ? "bg-secondary" : "bg-background"
      }`}
    >
      {(type === "classSelection" || type === "backgroundSelection") &&
      isPresent(modalSelectorProps) ? (
        <div>
          <p>{text}</p>
          <ModalSelector {...modalSelectorProps} />
        </div>
      ) : type === "statAllocation" && isPresent(statAllocationProps) ? (
        <div className="w-full">
          <p>{text}</p>
          <StatAllocation {...statAllocationProps} />
        </div>
      ) : type === "diceRoll" && isPresent(diceRollProps) ? (
        <div>
          <DiceRoll {...diceRollProps} />
        </div>
      ) : (
        (() => {
          const parsedSegments = parseDMActionSegments(text);

          return parsedSegments.map((segment, index) => {
            if (segment.type === "dmAction") {
              // Render DM action with special styling
              return (
                <div
                  key={index}
                  className="border border-white p-2 rounded my-2"
                >
                  {segment.content}
                </div>
              );
            } else {
              // Render normal text with sanitization
              return (
                <div
                  key={index}
                  dangerouslySetInnerHTML={{
                    __html: formatText(segment.content, role),
                  }}
                />
              );
            }
          });
        })()
      )}
    </div>
  );
};
