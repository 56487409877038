import React, { useState, useEffect, useRef } from "react";
import {
  TerminalEntry,
  TerminalEntryProps,
} from "./components/terminal-entry/TerminalEntry";
import { isPresent } from "./utils/utility";
import {
  generateCharacterSummary,
  getWelcomeMessage,
  IntroFlowState,
  introFlowStates,
} from "./utils/intro-utils";
import {
  DEFAULT_CHARACTER,
  setCharacterAC,
  setCharacterStats,
} from "./utils/character-utils";
import { setCharacterBackground } from "./utils/character-utils";
import { MAX_CHAT_LENGTH } from "./data/chat";
import { initializeWorld, sendGameMessage } from "./utils/game-utils";
import { initializeCharacter } from "./utils/character-utils";
import { CharacterBackground, CLASSES } from "./data/classes";
import { useCallback } from "react";
import { StatValue } from "./components/terminal-entry/stat-allocation/StatAllocation";
import { SidePanel } from "./components/side-panel/SidePanel";
import { Modal } from "./components/modal/Modal";
import {
  Action,
  BodyLocation,
  Condition,
  DEFAULT_INVENTORY,
  DMActionsMessage,
  isArmor,
  isWeapon,
  Item,
  RollInfo,
} from "@ai-dm/utils";
import { Character, isValidStat, Stat } from "@ai-dm/utils";

let socket: WebSocket | null;

export const App: React.FC = () => {
  const [entries, setEntries] = useState<TerminalEntryProps[]>([]);
  const [userInput, setUserInput] = useState<string>("");
  const [flowState, setFlowState] = useState<IntroFlowState | "game">(
    "welcome"
  );
  const [isStreaming, setIsStreaming] = useState<boolean>(false);
  const terminalRef = useRef<HTMLDivElement>(null);
  const [imageUrl, setImageUrl] = useState<string>("");
  const isGeneratingImageRef = useRef(false);
  const [selectedClass, setSelectedClass] = useState<string | null>(null);
  const [pendingRoll, setPendingRoll] = useState<{
    type: Stat;
    dc: number;
  } | null>(null);
  const [sidePanelWidth, setSidePanelWidth] = useState(400);
  const [showCharacterSheetModal, setShowCharacterSheetModal] = useState(false);
  // Game State
  const [character, setCharacter] = useState<Character>(initializeCharacter);
  const [inventory, setInventory] = useState<Item[]>([]);
  const [world, setWorld] = useState(initializeWorld);

  // logging in stuff
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [authMode, setAuthMode] = useState<"login" | "signup" | null>(null);

  const characterRef = useRef<Character>(character);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const generateImage = useCallback(async (text: string) => {
    if (isGeneratingImageRef.current) return;
    isGeneratingImageRef.current = true;

    try {
      const response = await fetch("/generate-image", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ text }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const blob = await response.blob();
      const imageUrl = URL.createObjectURL(blob);
      setImageUrl(imageUrl);
    } catch (error) {
      console.error("Failed to generate image:", error);
    }

    isGeneratingImageRef.current = false;
  }, []);

  useEffect(() => {
    characterRef.current = character;
  }, [character]);

  useEffect(() => {
    const userToken = localStorage.getItem("userToken");

    if (userToken) {
      setFlowState("worldCreation");
      setEntries((prevEntries) => [
        {
          role: "assistant",
          content: "What would you like to call your world?",
        },
      ]);
    } else {
      setFlowState("welcome");
      setEntries(getWelcomeMessage());
    }

    const connectWebSocket = () => {
      socket = new WebSocket("ws://localhost:8080");

      socket.onopen = () => {
        console.log("WebSocket connection established");
      };

      socket.onerror = (error) => {
        console.error("WebSocket Error:", error);
      };

      socket.onmessage = (event: MessageEvent) => {
        const data = event.data;

        if (typeof data === "string") {
          try {
            // Regular text will fail to parse as a json, and logic is handled in catch block
            const parsedData = JSON.parse(data);
            console.log("parsedData");
            //#region ROLL REQUEST
            if (parsedData.type === "roll_request") {
              setIsStreaming(false);

              const rollInfo = parsedData.rollInfo as RollInfo;
              const stat = (
                isValidStat(rollInfo.type) ? rollInfo.type : "dex"
              ) as Stat;
              const dc = rollInfo.dc;

              const characterStatValue = characterRef.current.stats[stat];
              if (characterStatValue !== 0) {
                rollInfo.roll.push(characterStatValue);
              }
              console.log("Stats", character.stats);
              console.log("stat", stat);
              console.log("Stat Value", characterStatValue);

              console.log(JSON.stringify(rollInfo.roll, null, 2));

              const results: { value: number; type: string }[] = [];
              let totalRoll = 0;

              const sortedRoll = [...rollInfo.roll].sort((a, b) => {
                const getSortValue = (item: number | { sides: number }) => {
                  if (typeof item === "number") return -item; // Negative numbers sort higher
                  if (item && typeof item === "object" && "sides" in item)
                    return item.sides * 100;
                  return 0;
                };
                return getSortValue(b) - getSortValue(a);
              });

              sortedRoll.forEach((item) => {
                if (typeof item === "number") {
                  results.push({ value: item, type: "constant" }); // Push numeric constants
                  totalRoll += item;
                } else if (
                  item &&
                  typeof item === "object" &&
                  "sides" in item
                ) {
                  const roll = Math.floor(Math.random() * item.sides) + 1; // Roll the dice
                  results.push({ value: roll, type: `d${item.sides}` }); // Push dice rolls
                  totalRoll += roll;
                } else {
                  console.error("Unexpected roll item:", item); // Handle unexpected types
                }
              });
              const d20Result = results.find((r) => r.type === "d20")?.value;
              const success = totalRoll >= dc;
              let rollOutcome = success ? "succeeding" : "failing";
              if (d20Result === 20) {
                rollOutcome = "critically succeeding";
              } else if (d20Result === 1) {
                rollOutcome = "critically failing";
              }
              const rollContent = `I am rolling a DC ${dc} ${stat.toUpperCase()} check. I rolled a ${totalRoll}, ${rollOutcome}.`;

              setPendingRoll({ type: stat, dc });

              setEntries((prevEntries) => [
                ...prevEntries,
                {
                  role: "assistant",
                  content: parsedData.rollInfo.explanation,
                },
                {
                  role: "user",
                  content: rollContent,
                  type: "diceRoll",
                  diceRollProps: {
                    type: stat,
                    roll: rollInfo.roll,
                    dc: dc,
                    results: results,
                    totalRoll: totalRoll,
                    onRollReveal: () => {
                      const newEntries: TerminalEntryProps[] = [
                        ...prevEntries,
                        {
                          role: "assistant",
                          content: parsedData.rollInfo.explanation,
                        },
                        {
                          role: "user",
                          content: rollContent,
                        },
                      ];
                      setIsStreaming(true);
                      sendGameMessage(
                        newEntries,
                        {
                          character,
                          inventory,
                          world,
                          previousRoll: {
                            dc,
                            roll: results.reduce(
                              (total, item) => total + item.value,
                              0
                            ),
                            stat,
                            outcome: rollOutcome,
                          },
                        },
                        socket,
                        MAX_CHAT_LENGTH
                      );
                      setPendingRoll(null);
                    },
                  },
                },
              ]);

              //#endregion
              //#region DM ACTION REQUEST
            } else if (parsedData.type === "dm_actions") {
              console.log("DM_ACTIONS", parsedData);
              const dmActions = parsedData as DMActionsMessage;
              for (const action of dmActions.actions) {
                if (action.type === "room_movement") {
                  const targetRoom = world.currentPOI?.rooms.find(
                    (room) => room.name === action.to_room
                  );

                  if (targetRoom) {
                    console.log(
                      `Moving rooms from ${world.currentRoom} to ${targetRoom}`
                    );
                    setWorld((prevWorld) => ({
                      ...prevWorld,
                      currentRoom: action.to_room,
                    }));
                    setEntries((prevEntries) => {
                      const updatedEntries = [...prevEntries];

                      // If there is at least 1 entry, append DM actions to the last assistant entry
                      if (updatedEntries.length > 0) {
                        for (let i = updatedEntries.length - 1; i >= 0; i--) {
                          if (updatedEntries[i].role === "assistant") {
                            updatedEntries[i] = {
                              ...updatedEntries[i],
                              content:
                                updatedEntries[i].content +
                                `%DM_ACTION_START% ${characterRef.current.name} moves to ${action.to_room} %DM_ACTION_END%`,
                            };
                            break;
                          }
                        }
                      }

                      return updatedEntries;
                    });
                  } else {
                    console.error(
                      `Attempted to move to room "${action.to_room}" which doesn't exist in POI "${world.currentPOI?.name}"`
                    );
                  }
                }
              }
              //#endregion
            } else if (parsedData.type === "message_stop") {
              setIsStreaming(false);
              console.log("Streaming Ended");
              setEntries((prevEntries) => {
                const updatedEntries = [...prevEntries];
                const lastEntryIndex = updatedEntries.length - 1;

                if (lastEntryIndex >= 1) {
                  const lastEntry = updatedEntries[lastEntryIndex]?.content;

                  if (lastEntry) {
                    console.log("generating image");
                    generateImage(lastEntry);
                  } else {
                    console.error(
                      "No valid text available for image generation"
                    );
                  }
                } else {
                  console.error("Not enough entries to generate an image");
                }

                return updatedEntries;
              });
              if (inputRef.current) {
                setTimeout(() => {
                  inputRef.current?.focus();
                }, 0);
              }
            } else {
              console.log("Unknown message type:", parsedData);
            }
          } catch (error) {
            console.log("Error", error);
            setEntries((prevEntries) => {
              const updatedEntries = [...prevEntries];
              const lastEntryIndex = updatedEntries.length - 1;
              const lastEntry = updatedEntries[lastEntryIndex];

              if (lastEntry && lastEntry.role === "assistant") {
                updatedEntries[lastEntryIndex] = {
                  ...lastEntry,
                  content: lastEntry.content + data,
                };
              } else {
                updatedEntries.push({
                  role: "assistant",
                  content: data,
                });
              }
              return updatedEntries;
            });
          }
        }
      };

      socket.onclose = (event) => {
        console.log("WebSocket connection closed", event);
        setTimeout(() => {
          console.log("Reconnecting...");
          connectWebSocket();
        }, 3000);
      };
    };

    connectWebSocket();

    return () => {
      if (socket) socket.close();
    };
  }, [generateImage]);

  useEffect(() => {
    if (terminalRef.current) {
      const { scrollHeight, clientHeight } = terminalRef.current;
      terminalRef.current.scrollTop = scrollHeight - clientHeight;
    }
  }, [entries, character.stats]);

  //#region COMMANDS
  const COMMAND_LIST: string[] = ["!default", "!level", "!character"];

  const isCommand = (userInput: string): boolean => {
    const [action, ...params] = userInput.toLowerCase().split(" ");
    if (COMMAND_LIST.includes(action)) {
      return true;
    }
    return false;
  };

  const processCommands = (userInput: string) => {
    const [action, ...params] = userInput.toLowerCase().split(" ");
    switch (action) {
      case "!default":
        setCharacter(DEFAULT_CHARACTER);
        setInventory(DEFAULT_INVENTORY);
        setWorld((prevWorld) => ({ ...prevWorld, name: "Exandria" }));
        clearEntries();
        setEntries([
          {
            role: "assistant",
            content:
              "Welcome Adrian! You are standing outside of a dungeon. What do you do?",
          },
        ]);
        setFlowState("game");
        setUserInput("");
        break;
      case "!level":
        const levelIncrease = parseInt(params[0]);
        if (!isNaN(levelIncrease)) {
          const newLevel = character.level + levelIncrease;
          const newTotalCP =
            character.character_points.total + 10 * levelIncrease;
          updateCharacter("level", newLevel);
          updateCharacter("character_points", {
            ...character.character_points,
            total: newTotalCP,
          });
        }
        setUserInput("");
        break;
      case "!character":
        setShowCharacterSheetModal(true);
        setUserInput("");
        break;
      default:
        console.log("Unknown command");
    }
  };
  //#endregion

  //#region USER INPUT
  const processUserInput = () => {
    if (!userInput.trim() || isStreaming) return;
    if (isCommand(userInput)) {
      processCommands(userInput);
    } else if (flowState === "game") {
      const newEntries: TerminalEntryProps[] = [
        ...entries,
        { role: "user", content: userInput },
        { role: "assistant", content: "" },
      ];
      setEntries(newEntries);
      setIsStreaming(true);
      sendGameMessage(
        newEntries,
        {
          character,
          inventory,
          world,
        },
        socket,
        MAX_CHAT_LENGTH
      );
    } else if (introFlowStates.includes(flowState)) {
      processIntroFlow();
    }

    setUserInput("");
    if (flowState === "game") {
      setIsStreaming(true);
    } else {
      setIsStreaming(false);
    }
  };
  //#endregion

  const handleUndo = () => {
    setIsStreaming(false);
    setPendingRoll(null);
    setEntries((prevEntries) => {
      if (prevEntries.length >= 1) {
        return prevEntries.slice(0, -1);
      }
      return prevEntries;
    });
    if (inputRef.current) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  };

  const handleClear = () => {
    setEntries((prevEntries) => {
      return [prevEntries[0]];
    });
  };

  const updateCharacter = (key: keyof Character, value: any) => {
    setCharacter((prevCharacter) => ({
      ...prevCharacter,
      [key]: value,
    }));
  };

  const setCharacterActions = (
    character: Character,
    inventory: Item[]
  ): Action[] => {
    const actions: Action[] = [];

    actions.push({
      name: "Unarmed Strike",
      description:
        "A basic melee attack using your fists or body. Requires a d20 strength check.",
      abilityCheck: {
        stat: ["str"],
      },
      damage: [Math.max(1 + character.stats.str, 1)],
      range: "Close",
    });

    for (const ability of character.abilities) {
      if (isPresent(ability.actions)) {
        for (const action of ability.actions) {
          actions.push(action);
        }
      }
    }

    for (const item of inventory) {
      if (isPresent(item.actions)) {
        for (const action of item.actions) {
          actions.push(action);
        }
      }
    }

    return actions;
  };

  const setCharacterConditions = (
    character: Character,
    inventory: Item[]
  ): Condition[] => {
    const conditions: Condition[] = [];

    for (const ability of character.abilities) {
      if (isPresent(ability.conditions)) {
        for (const condition of ability.conditions) {
          conditions.push(condition);
        }
      }
    }

    for (const item of inventory) {
      if (isPresent(item.conditions)) {
        if (item.conditions.mustBeEquipped) {
          if (item.equipped) {
            for (const condition of item.conditions.conditionList) {
              conditions.push(condition);
            }
          }
        } else {
          for (const condition of item.conditions.conditionList) {
            conditions.push(condition);
          }
        }
      }
    }

    return conditions;
  };

  const handleEquipToggle = (itemToUpdate: Item) => {
    const result = updateInventory(inventory, itemToUpdate);
    if (result.success) {
      setInventory(result.inventory);

      // Recalculating AC, actions, and conditions
      setCharacter((prevChar) => {
        const newChar = { ...prevChar };
        newChar.ac = setCharacterAC(newChar, result.inventory);
        newChar.actions = setCharacterActions(newChar, result.inventory);
        newChar.conditions = setCharacterConditions(newChar, result.inventory);

        return newChar;
      });
    }
  };

  const updateInventory = (
    inventory: Item[],
    itemToUpdate: Item
  ): {
    success: boolean;
    inventory: Item[];
    message?: string;
  } => {
    // equippable item logic
    if (itemToUpdate.equipable) {
      return tryEquipItem(inventory, itemToUpdate);
    }
    // just update the item for non-equipable
    const newInventory = [...inventory];
    const itemIndex = newInventory.findIndex(
      (item) => item.name === itemToUpdate.name
    );

    if (itemIndex !== -1) {
      newInventory[itemIndex] = itemToUpdate;
      return {
        success: true,
        inventory: newInventory,
      };
    }

    return {
      success: false,
      inventory,
      message: "Item not found in inventory",
    };
  };

  // can an item be equipped (is a slot already taken)
  const tryEquipItem = (
    inventory: Item[],
    itemToEquip: Item
  ): { success: boolean; inventory: Item[]; message?: string } => {
    // item isn't equipable, return early
    if (!itemToEquip.equipable) {
      return {
        success: false,
        inventory,
        message: "This item cannot be equipped",
      };
    }

    // We'll do your slot check logic or "two hand" logic here.
    // The snippet below is simplified to ensure only 1 item per location
    // (excluding up to two 'Hand' items).
    const newInventory = [...inventory];
    const itemIndex = newInventory.findIndex(
      (item) => item.name === itemToEquip.name
    );
    if (itemIndex === -1) {
      return { success: false, inventory, message: "Item not found" };
    }
    // If item is already equipped, unequip it
    if (newInventory[itemIndex].equipped) {
      newInventory[itemIndex] = { ...itemToEquip, equipped: false };
      return { success: true, inventory: newInventory, message: "Unequipped" };
    }

    // Otherwise, try equipping it.
    // If it's a 'Hand' location, you can have up to 2 items in 'Hand'.
    // If any other location, can only equip one item in that location.
    // This is a simple example:
    if (
      isArmor(itemToEquip) &&
      isLocationOccupied(newInventory, itemToEquip.location)
    ) {
      return {
        success: false,
        inventory,
        message: `Cannot equip item – ${itemToEquip.location} slot is occupied`,
      };
    }
    // If it's a weapon in 'Hand' (or location is 'Hand'), allow up to 2
    // We'll do a quick check if we already have 2 items in the 'Hand'
    if (isWeapon(itemToEquip) && itemToEquip.location === "Hand") {
      const handsEquipped = newInventory.filter(
        (i) => i.equipped && isWeapon(i) && i.location === "Hand"
      ).length;
      if (handsEquipped >= 2) {
        return {
          success: false,
          inventory,
          message: "Cannot equip – both hands are occupied",
        };
      }
    }

    newInventory[itemIndex] = { ...itemToEquip, equipped: true };
    return {
      success: true,
      inventory: newInventory,
      message: "Equipped",
    };
  };

  const isLocationOccupied = (
    inventory: Item[],
    location: BodyLocation
  ): boolean => {
    return inventory.some(
      (i) => i.equipped && isArmor(i) && i.location === location
    );
  };

  //#region INTRO FLOW
  const processIntroFlow = async () => {
    if (flowState === "welcome") {
      if (userInput.toLowerCase() === "login") {
        setAuthMode("login");
        setEntries((prevEntries) => [
          ...prevEntries,
          { role: "user", content: userInput },
          { role: "assistant", content: "Please enter your username." },
        ]);
        setFlowState("username");
      } else if (userInput.toLowerCase() === "signup") {
        setAuthMode("signup");
        setEntries((prevEntries) => [
          ...prevEntries,
          { role: "user", content: userInput },
          { role: "assistant", content: "Please enter a new username." },
        ]);
        setFlowState("username");
      } else {
        setEntries((prevEntries) => [
          ...prevEntries,
          { role: "user", content: userInput },
          {
            role: "assistant",
            content: "Please type 'login' or 'signup' to proceed.",
          },
        ]);
      }
    } else if (flowState === "username") {
      setUsername(userInput);
      setEntries((prevEntries) => [
        ...prevEntries,
        { role: "user", content: userInput },
        { role: "assistant", content: "Please enter your password." },
      ]);
      setFlowState("password");
    } else if (flowState === "password") {
      setPassword(userInput);
      try {
        const endpoint = authMode === "login" ? "/login" : "/signup";
        const response = await fetch(`http://localhost:3000${endpoint}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ username, password: userInput }),
        });
        const data = await response.json();

        if (response.ok && data.token) {
          localStorage.setItem("userToken", data.token);
          setEntries((prevEntries) => [
            ...prevEntries,
            { role: "user", content: "*****" },
            {
              role: "assistant",
              content:
                authMode === "login"
                  ? "Login successful. Welcome back! What would you like to name your world?"
                  : "Signup successful. Welcome to your world creation! What would you like to name your world?",
            },
          ]);
          setFlowState("worldCreation");
        } else {
          setEntries((prevEntries) => [
            ...prevEntries,
            { role: "user", content: "*****" },
            {
              role: "assistant",
              content: `Authentication failed: ${data.message}. Please try again.`,
            },
          ]);
          setFlowState("welcome");
        }
      } catch (error) {
        console.error("Authentication Error:", error);
        setEntries((prevEntries) => [
          ...prevEntries,
          { role: "user", content: "*****" },
          {
            role: "assistant",
            content:
              "No associated account. Please reach out to me@playzain.com for closed alpha access. Get excited for an endless adventure in your own fantasy world! Build relationships with npcs. Conquer dangerous dungeons. Cross realms. Defeat monsters. Build your own realm. Do anything you want. ",
          },
        ]);
        setFlowState("welcome");
      }
      setAuthMode(null);
    } else if (flowState === "worldCreation") {
      setWorld((prevWorld) => ({ ...prevWorld, name: userInput }));
      setEntries((prevEntries) => [
        ...prevEntries,
        { role: "user", content: userInput },
        {
          role: "assistant",
          content: "Please select a class:",
          type: "classSelection",
          modalSelectorProps: {
            type: "classSelection",
            options: CLASSES.map((cls) => cls.name),
            onSelect: (sel) => handleSelection(sel, "classSelection"),
            selectedOption: selectedClass,
          },
        },
      ]);
      setFlowState("classSelection");
    } else if (flowState === "classSelection") {
      // handled by handleSelection
    } else if (flowState === "name") {
      setCharacter((prevCharacter) => ({
        ...prevCharacter,
        name: userInput,
        description: `Name: ${userInput}`,
      }));
      setEntries((prevEntries) => [
        ...prevEntries,
        { role: "user", content: userInput },
        {
          role: "assistant",
          content: "Now, let's allocate your character's stats:",
          type: "statAllocation",
          statAllocationProps: {
            onComplete: handleStatAllocationComplete,
          },
        },
      ]);
      setFlowState("statAllocation");
    } else if (flowState === "statAllocation") {
      // handled by handleStatAllocation
    } else if (flowState === "gender") {
      setCharacter((prevCharacter) => ({
        ...prevCharacter,
        description: prevCharacter.description + `\nGender: ${userInput}`,
      }));
      setEntries((prevEntries) => [
        ...prevEntries,
        { role: "user", content: userInput },
        { role: "assistant", content: "Please enter your character's height:" },
      ]);
      setCharacter((prevCharacter) => ({
        ...prevCharacter,
        description: prevCharacter.description + `\nGender: ${userInput}`,
      }));
      setFlowState("height");
    } else if (flowState === "height") {
      setCharacter((prevCharacter) => ({
        ...prevCharacter,
        description: prevCharacter.description + `\nHeight: ${userInput}`,
      }));
      setEntries((prevEntries) => [
        ...prevEntries,
        { role: "user", content: userInput },
        {
          role: "assistant",
          content: "Please enter your character's hair color:",
        },
      ]);
      setFlowState("hair");
    } else if (flowState === "hair") {
      setCharacter((prevCharacter) => ({
        ...prevCharacter,
        description: prevCharacter.description + `\nHair Color: ${userInput}`,
      }));
      setEntries((prevEntries) => [
        ...prevEntries,
        { role: "user", content: userInput },
        {
          role: "assistant",
          content: "Please enter your character's skin color:",
        },
      ]);
      setFlowState("skinColor");
    } else if (flowState === "skinColor") {
      setCharacter((prevCharacter) => ({
        ...prevCharacter,
        description: prevCharacter.description + `\nSkin Color: ${userInput}`,
      }));
      setEntries((prevEntries) => [
        ...prevEntries,
        { role: "user", content: userInput },
        {
          role: "assistant",
          content: "Please enter your character's facial appearance:",
        },
      ]);
      setFlowState("facialAppearance");
    } else if (flowState === "facialAppearance") {
      setCharacter((prevCharacter) => ({
        ...prevCharacter,
        description:
          prevCharacter.description + `\nFacial Appearance: ${userInput}`,
      }));
      setEntries((prevEntries) => [
        ...prevEntries,
        { role: "user", content: userInput },
        {
          role: "assistant",
          content: "Please enter your character's bodily appearance:",
        },
      ]);
      setFlowState("bodilyAppearance");
    } else if (flowState === "bodilyAppearance") {
      setEntries((prevEntries) => [
        ...prevEntries,
        { role: "user", content: userInput },
      ]);

      const summary = await generateCharacterSummary(
        character.description + `\nBodily Appearance: ${userInput}`
      );
      setCharacter((prevCharacter) => ({
        ...prevCharacter,
        description: summary,
      }));

      clearEntries();
      setEntries([
        {
          role: "assistant",
          content:
            "Congratulations, your character is complete! You are standing outside of a dungeon. What do you do?",
        },
      ]);
      setFlowState("game");
    }
  };
  //#endregion

  const handleSelection = (selection: string, selectionType: string) => {
    const selectedClass = CLASSES.find((cls) => cls.name === selection);
    if (isPresent(selectedClass)) {
      setCharacter((prevCharacter) =>
        setCharacterBackground(
          prevCharacter,
          selectedClass as CharacterBackground
        )
      );
      setInventory(selectedClass?.startingEquipment || []);
      setSelectedClass(selection);
      setEntries((prevEntries) => [
        ...prevEntries,
        { role: "user", content: `${selection} selected` },
        { role: "assistant", content: "Please enter your character's name:" },
      ]);
      setFlowState("name");
      if (inputRef.current) {
        setTimeout(() => {
          inputRef.current?.focus();
        }, 0);
      }
    }
  };

  const handleStatAllocationComplete = (stats: StatValue[]) => {
    setCharacter((prevCharacter) =>
      setCharacterStats(prevCharacter, stats, inventory)
    );
    setEntries((prevEntries) => [
      ...prevEntries,
      { role: "user", content: "Stats allocated" },
      {
        role: "assistant",
        content: "Great! Now, please enter your character's gender:",
      },
    ]);
    setFlowState("gender");
    if (inputRef.current) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  };

  const getInputPlaceholder = () => {
    if (isStreaming) return "Please wait...";
    if (pendingRoll !== null) return "Please roll...";
    switch (flowState) {
      case "classSelection":
        return "Please select a class...";
      case "backgroundSelection":
        return "Please select a background...";
      case "statAllocation":
        return "Please allocate your stats...";
      default:
        return "Type here...";
    }
  };

  const clearEntries = () => {
    setEntries([]);
  };

  return (
    <main className="flex h-screen bg-background text-text font-mono">
      <div className="flex-1 flex flex-col justify-start mr-8 p-8 min-w-[200px]">
        <div
          className="overflow-y-auto flex-grow scrollbar-hide"
          ref={terminalRef}
        >
          {entries.map((entry, index) => (
            <TerminalEntry
              key={index}
              content={entry.content}
              role={entry.role}
              type={entry.type}
              modalSelectorProps={entry.modalSelectorProps}
              statAllocationProps={entry.statAllocationProps}
              diceRollProps={entry.diceRollProps}
            />
          ))}
        </div>
        <div className="mt-auto w-full">
          <div className="flex items-start bg-secondary p-2 rounded">
            <span
              className={`text-xl mr-2 ${
                userInput ? "text-white" : "text-gray-400"
              }`}
              style={{
                alignSelf: userInput ? "start" : "center", // Align dynamically
              }}
            >
              &gt;
            </span>
            <textarea
              className="w-full bg-secondary text-white border-none outline-none font-mono text-xl resize-none overflow-y-auto"
              ref={inputRef}
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              onInput={(e) => {
                const target = e.target as HTMLTextAreaElement;
                target.style.height = "auto";
                target.style.height = `${Math.min(target.scrollHeight, 200)}px`;
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  processUserInput();
                }
              }}
              placeholder={getInputPlaceholder()}
              rows={1}
              disabled={
                isStreaming ||
                flowState === "classSelection" ||
                flowState === "backgroundSelection" ||
                flowState === "statAllocation" ||
                pendingRoll !== null
              }
            />
          </div>
        </div>
      </div>
      <SidePanel
        width={sidePanelWidth}
        setWidth={setSidePanelWidth}
        imageUrl={imageUrl || "/images/zain-title.png"}
        character={character}
        world={world}
        inventory={inventory}
        updateCharacter={updateCharacter}
        onEquipToggle={handleEquipToggle}
        onUndo={handleUndo}
        onClear={handleClear}
      />
      {showCharacterSheetModal && (
        <Modal
          onClose={() => setShowCharacterSheetModal(false)}
          characterSheetModalProps={{
            character,
          }}
        />
      )}
    </main>
  );
};

export default App;
