"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_INVENTORY = exports.BACKPACK = void 0;
const armor_1 = require("./armor");
const weapons_1 = require("./weapons");
exports.BACKPACK = {
    name: "Backpack",
    type: "Other",
    equipable: true,
    equipped: false,
    description: "A sturdy pack with plenty of room for all your adventuring gear.",
    slots: 0,
    value: 20,
};
exports.DEFAULT_INVENTORY = [
    Object.assign(Object.assign({}, weapons_1.LONGSWORD), { equipped: true }),
    Object.assign(Object.assign({}, armor_1.CHAINMAIL), { equipped: true }),
    Object.assign(Object.assign({}, armor_1.SHIELD), { equipped: true }),
    Object.assign(Object.assign({}, exports.BACKPACK), { equipped: true }),
];
