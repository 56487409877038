"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Faction = exports.PointOfInterest = exports.Tile = exports.OceanBiome = exports.LandBiome = exports.BiomeType = void 0;
class BiomeType {
    constructor(name, color, temperature) {
        this.name = name;
        this.color = color;
        this.temperature = temperature;
    }
    toString() {
        return this.name;
    }
}
exports.BiomeType = BiomeType;
class LandBiome extends BiomeType {
    constructor(name, color, temperature) {
        super(name, color, temperature);
    }
}
exports.LandBiome = LandBiome;
LandBiome.Coast = new LandBiome("Coast", "#33cccc", 1);
LandBiome.Beach = new LandBiome("Beach", "#ffff99", 2);
LandBiome.Plains = new LandBiome("Plains", "#c2cc7a", 2);
LandBiome.Grassland = new LandBiome("Grassland", "#88aa55", 3);
LandBiome.Forest = new LandBiome("Forest", "#228B22", 2);
LandBiome.PineForest = new LandBiome("Pine Forest", "#2f4f2f", 1);
LandBiome.Desert = new LandBiome("Desert", "#e9ddb3", 4);
LandBiome.Savanna = new LandBiome("Savanna", "#d2b96f", 3);
LandBiome.Volcano = new LandBiome("Volcano", "#800000", 5);
LandBiome.Swamp = new LandBiome("Swamp", "#2f4f4f", 2);
LandBiome.RainForest = new LandBiome("Rain Forest", "#004d00", 4);
LandBiome.Tundra = new LandBiome("Tundra", "#96aaaa", -2);
LandBiome.Ice = new LandBiome("Ice", "#e0ffff", -3);
LandBiome.Mountain = new LandBiome("Mountain", "#808080", 0);
LandBiome.HighMountain = new LandBiome("High Mountain", "#a0a0a0", -1);
LandBiome.RedwoodForest = new LandBiome("Redwood Forest", "#4b2e2f", 2);
LandBiome.TemperateRainforest = new LandBiome("Temperate Rainforest", "#006400", 3);
LandBiome.Steppe = new LandBiome("Steppe", "#c2b280", 3);
LandBiome.TropicalForest = new LandBiome("Tropical Forest", "#0b6623", 4);
LandBiome.Marsh = new LandBiome("Marsh", "#708090", 2);
class OceanBiome extends BiomeType {
    constructor(name, color, temperature) {
        super(name, color, temperature);
    }
}
exports.OceanBiome = OceanBiome;
OceanBiome.ShallowOcean = new OceanBiome("Shallow Ocean", "#0000ff", -1);
OceanBiome.MediumOcean = new OceanBiome("Medium Ocean", "#000099", -2);
OceanBiome.DeepOcean = new OceanBiome("Deep Ocean", "#000033", -3);
class Tile {
    constructor(biome, pointsOfInterest) {
        this.biome = biome;
        this.pointsOfInterest = pointsOfInterest;
    }
}
exports.Tile = Tile;
class PointOfInterest {
    constructor(title) {
        this.title = title;
    }
}
exports.PointOfInterest = PointOfInterest;
class Faction {
    constructor(name, power, x, y, relationships, color) {
        this.name = name;
        this.power = power;
        this.x = x;
        this.y = y;
        this.relationships = relationships;
        this.color = color;
    }
}
exports.Faction = Faction;
