import { Character, Metadata } from "@ai-dm/utils";
import { World } from "@ai-dm/utils";
import { Armor, Item, Weapon } from "@ai-dm/utils";
import { DELPHIAN_TOMB } from "../data/dungeon";
import { TerminalEntryProps } from "../components/terminal-entry/TerminalEntry";

export interface MessageStringParam {
  content: string;
  role: "user" | "assistant";
}

const convertToMessageParam = (
  entry: TerminalEntryProps
): MessageStringParam => ({
  content: entry.content,
  role: entry.role,
});

export const sendGameMessage = (
  entries: TerminalEntryProps[],
  metadata: Metadata,
  socket: WebSocket | null,
  maxChatLength: number = 10
) => {
  if (!socket) return;

  const filteredEntries = [...entries];
  if (
    filteredEntries.length > 0 &&
    filteredEntries[filteredEntries.length - 1].role === "assistant"
  ) {
    filteredEntries.pop();
  }

  const recentMessages = filteredEntries
    .slice(-maxChatLength)
    .map(convertToMessageParam);

  const websocketMessage = {
    messages: recentMessages,
    metadata: metadata,
  };

  console.log("WebSocket message sent:", websocketMessage);
  socket.send(JSON.stringify(websocketMessage));
};

export const handleUserInputForGame = (
  userInput: string,
  entries: any[],
  character: Character,
  inventory: (Item | Weapon | Armor)[],
  setEntries: (entries: any[]) => void,
  setIsStreaming: (streaming: boolean) => void,
  socket: WebSocket | null,
  MAX_CHAT_LENGTH: number,
  isPresent: (value: any) => boolean
) => {
  const newEntries = [
    ...entries,
    { role: "user", content: userInput },
    { role: "assistant", content: "" },
  ];
  setEntries(newEntries);
  setIsStreaming(true);
  console.log("User message sent:", userInput);
  const messageHistory = [...entries, { role: "user", content: userInput }]
    .slice(-MAX_CHAT_LENGTH)
    .map(({ role, content }) => ({ role, content }));

  const websocketMessage = {
    messages: messageHistory,
    metadata: {
      character: character,
      inventory: inventory,
    },
  };
  if (isPresent(socket)) {
    console.log("WebSocket message sent:", websocketMessage);
    socket?.send(JSON.stringify(websocketMessage));
  }
};

export const initializeWorld = (): World => ({
  name: "",
  currentPOI: DELPHIAN_TOMB,
  currentRoom: "Tomb Entrance",
  currentHex: { x: 0, y: 0 },
});
