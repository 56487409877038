import React, { useState, useCallback, memo, useEffect } from "react";
import { Tile, Faction } from "../../../shared-utils/src/map";

interface ProcessedFaction {
  name: string;
  biome: string;
  power: string | number;
  x: number;
  y: number;
  color: string;
}

interface MapData {
  map: Tile[][];
  factions: ProcessedFaction[];
}

interface TileComponentProps {
  tile: Tile;
  x: number;
  y: number;
  background: string;
  onHover: (
    tile: Tile | null,
    coords: { x: number; y: number } | null,
    e: React.MouseEvent<HTMLDivElement>
  ) => void;
}

const TileComponent = memo(
  ({ tile, x, y, background, onHover }: TileComponentProps) => {
    return (
      <div
        style={{
          aspectRatio: "1 / 1",
          width: "100%",
          background,
        }}
        onMouseEnter={(e) => onHover(tile, { x, y }, e)}
        onMouseLeave={(e) => onHover(null, null, e)}
      />
    );
  }
);

const InfoBubble: React.FC<{
  tile: Tile;
  tileCoords: { x: number; y: number };
  position: { x: number; y: number };
}> = ({ tile, tileCoords, position }) => {
  return (
    <div
      style={{
        position: "fixed",
        top: position.y + 10,
        left: position.x + 10,
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        color: "white",
        padding: "10px",
        borderRadius: "5px",
        pointerEvents: "none",
        zIndex: 1000,
        maxWidth: "200px",
      }}
    >
      <strong>Biome:</strong> {tile.biome.name}
      <br />
      {tile.pointsOfInterest && tile.pointsOfInterest.length > 0 && (
        <>
          <strong>Points of Interest:</strong>
          <ul>
            {tile.pointsOfInterest.map((poi, index) => (
              <li key={index}>{poi.title}</li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

const MapGenDebug: React.FC = () => {
  const [map, setMap] = useState<MapData | null>(null);
  const [hoveredTile, setHoveredTile] = useState<Tile | null>(null);
  const [hoveredTileCoords, setHoveredTileCoords] = useState<{
    x: number;
    y: number;
  } | null>(null);
  const [hoverPosition, setHoverPosition] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });
  const [loading, setLoading] = useState(false);
  const [width, setWidth] = useState(50);
  const [height, setHeight] = useState(50);

  // Panel states
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [expandFactions, setExpandFactions] = useState(false);

  // Faction visibility state
  const [factionVisibility, setFactionVisibility] = useState<{
    [key: string]: boolean;
  }>({});

  // Fetch map data
  const fetchMap = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch("http://localhost:3000/generate-map", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ width, height }),
      });
      if (!response.ok) {
        throw new Error("Failed to fetch map data.");
      }
      const data: any = await response.json();

      if (
        data.map &&
        Array.isArray(data.map) &&
        data.factions &&
        Array.isArray(data.factions)
      ) {
        // Process the map tiles
        const processedMap: Tile[][] = data.map.map((row: any[]) =>
          row.map((tile: any) => {
            const processedTile: Tile = {
              biome: tile.biome,
              pointsOfInterest: tile.pointsOfInterest,
              factionInfluence: tile.factionInfluence,
            };

            if ("faction_name" in tile && tile.faction_name) {
              const faction = data.factions.find(
                (faction: Faction) => faction.name === tile.faction_name
              );
              if (faction) {
                processedTile.faction = {
                  name: faction.name,
                  color: faction.color,
                };
              }
            }
            return processedTile;
          })
        );

        // Process the factions to match expected structure
        const processedFactions: ProcessedFaction[] = data.factions.map(
          (faction: any) => ({
            name: faction.name.faction_name,
            biome: faction.biome,
            power: faction.power || "N/A",
            x: faction.x || 0,
            y: faction.y || 0,
            color: faction.color || "#000000",
          })
        );

        // Assign faction to their respective tiles
        processedFactions.forEach((faction) => {
          const { x, y, name, color } = faction;
          if (
            y >= 0 &&
            y < processedMap.length &&
            x >= 0 &&
            x < processedMap[y].length
          ) {
            processedMap[y][x] = {
              ...processedMap[y][x],
              faction: {
                name,
                color,
              },
            };
          }
        });

        setMap({ map: processedMap, factions: processedFactions });

        // Initialize faction visibility to false for all factions
        const initialVisibility = processedFactions.reduce(
          (acc: { [key: string]: boolean }, faction: ProcessedFaction) => {
            acc[faction.name] = false;
            return acc;
          },
          {}
        );
        setFactionVisibility(initialVisibility);
      } else {
        throw new Error("Invalid map data format received.");
      }
    } catch (error) {
      console.error("Error fetching map:", error);
    } finally {
      setLoading(false);
    }
  }, [height, width]);

  // Handle hover events
  const handleHover = useCallback(
    (
      tile: Tile | null,
      coords: { x: number; y: number } | null,
      event: React.MouseEvent<HTMLDivElement>
    ) => {
      if (tile && coords) {
        setHoveredTile(tile);
        setHoveredTileCoords(coords);
        setHoverPosition({ x: event.clientX, y: event.clientY });
      } else {
        setHoveredTile(null);
        setHoveredTileCoords(null);
      }
    },
    []
  );

  // Compute if all factions are visible
  const allFactionsVisible =
    map && map.factions.length > 0
      ? map.factions.every((faction) => factionVisibility[faction.name])
      : false;

  // Handle toggling all factions
  const handleToggleAll = () => {
    if (map) {
      const newVisibility: { [key: string]: boolean } = {};
      map.factions.forEach((faction) => {
        newVisibility[faction.name] = !allFactionsVisible;
      });
      setFactionVisibility(newVisibility);
    }
  };

  // Handle toggling individual factions
  const handleToggleFaction = (factionName: string) => {
    setFactionVisibility((prev) => ({
      ...prev,
      [factionName]: !prev[factionName],
    }));
  };

  return (
    <div style={{ padding: "20px" }}>
      <h1>Map Generation Debug Page</h1>
      <p>Can view map generation process here to assess performance</p>

      <div style={{ marginBottom: "20px" }}>
        <label>
          Width: {width}
          <input
            type="range"
            min="10"
            max="500"
            value={width}
            onChange={(e) => setWidth(Number(e.target.value))}
            style={{ marginLeft: "10px", width: "100%" }}
          />
        </label>
        <br />
        <label>
          Height: {height}
          <input
            type="range"
            min="10"
            max="500"
            value={height}
            onChange={(e) => setHeight(Number(e.target.value))}
            style={{ marginLeft: "10px", width: "100%" }}
          />
        </label>
      </div>

      <button
        onClick={fetchMap}
        style={{
          backgroundColor: "#007BFF",
          color: "white",
          padding: "10px 20px",
          borderRadius: "5px",
          cursor: "pointer",
          marginTop: "10px",
        }}
        disabled={loading}
      >
        {loading ? "Generating..." : "Generate Map"}
      </button>

      {/* Top-right button to open/close panel */}
      <button
        style={{
          position: "fixed",
          top: "20px",
          right: "20px",
          backgroundColor: "#007BFF",
          color: "white",
          padding: "10px 20px",
          borderRadius: "5px",
          cursor: "pointer",
          zIndex: 2000,
        }}
        onClick={() => setIsPanelOpen(!isPanelOpen)}
      >
        {isPanelOpen ? "Close Panel" : "Open Panel"}
      </button>

      {/* The side panel */}
      {isPanelOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            right: 0,
            width: "300px",
            height: "100vh",
            backgroundColor: "#f8f9fa",
            boxShadow: "-2px 0 5px rgba(0, 0, 0, 0.3)",
            padding: "20px",
            overflowY: "auto",
            zIndex: 9999,
          }}
        >
          <div style={{ marginBottom: "10px" }}>
            {/* Close button inside the panel */}
            <button
              style={{
                backgroundColor: "#DC3545",
                color: "white",
                padding: "5px 10px",
                borderRadius: "3px",
                cursor: "pointer",
                float: "right",
                marginBottom: "10px",
              }}
              onClick={() => setIsPanelOpen(false)}
            >
              ×
            </button>

            <label
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                marginTop: "10px",
              }}
            >
              <input
                type="checkbox"
                checked={allFactionsVisible}
                onChange={handleToggleAll}
                style={{ marginRight: "8px" }}
                disabled={!map || map.factions.length === 0}
              />
              Show All Factions
            </label>

            <button
              style={{
                backgroundColor: "#FFC107",
                color: "black",
                padding: "10px",
                borderRadius: "5px",
                cursor: "pointer",
                width: "100%",
                textAlign: "left",
                marginTop: "10px",
              }}
              onClick={() => setExpandFactions(!expandFactions)}
            >
              Factions {expandFactions ? "▼" : "▶"}
            </button>

            {expandFactions && map && (
              <div
                style={{
                  marginLeft: "20px",
                  marginTop: "10px",
                  color: "black",
                }}
              >
                {map.factions.map((faction, index) => (
                  <div key={index} style={{ marginBottom: "15px" }}>
                    <label
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={factionVisibility[faction.name]}
                        onChange={() => handleToggleFaction(faction.name)}
                        style={{ marginRight: "8px" }}
                      />
                      <strong>{faction.name}</strong>
                    </label>
                    <div style={{ marginLeft: "24px" }}>
                      <div>Power: {faction.power}</div>
                      <div>
                        Position: ({faction.x}, {faction.y})
                      </div>
                      <div>Color: {faction.color}</div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}

      {map && (
        <div
          style={{
            position: "relative",
            border: "1px solid #ccc",
            overflow: "auto",
            width: "100%",
            maxHeight: "80vh",
            marginTop: "20px",
          }}
        >
          <div
            style={{
              display: "grid",
              gridTemplateColumns: `repeat(${width}, 1fr)`,
              gap: "1px",
            }}
          >
            {map.map.map((row, y) =>
              row.map((tile, x) => {
                let background = tile.biome.color;
                if (tile.faction && factionVisibility[tile.faction.name]) {
                  background = tile.faction.color;
                }
                return (
                  <TileComponent
                    key={`${y}-${x}`}
                    tile={tile}
                    x={x}
                    y={y}
                    background={background}
                    onHover={handleHover}
                  />
                );
              })
            )}
          </div>

          {hoveredTile && hoveredTileCoords && (
            <InfoBubble
              tile={hoveredTile}
              tileCoords={hoveredTileCoords}
              position={hoverPosition}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default MapGenDebug;
