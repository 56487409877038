import React from "react";
import { CharacterBackground } from "../../../data/classes";

export interface ClassModalProps {
  characterClass: CharacterBackground;
}

export const ClassModal: React.FC<ClassModalProps> = ({ characterClass }) => {
  return (
    <div>
      <h1 className="text-black text-center mb-4">{characterClass.name}</h1>
      <p className="italic mb-4">{characterClass.description}</p>
      <h2 className="text-black text-left">Skills</h2>
      <p className="mb-4">{characterClass.skills.join(", ")}</p>
      <h2 className="text-black text-left">Starting Equipment</h2>
      <ul>
        {characterClass.startingEquipment.map((item, index) => (
          <li key={index}>{item.name}</li>
        ))}
      </ul>
    </div>
  );
};
