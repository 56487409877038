"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isArmor = exports.isWeapon = void 0;
const isWeapon = (item) => {
    return item.type === "Weapon";
};
exports.isWeapon = isWeapon;
function isArmor(item) {
    return item.type === "Armor";
}
exports.isArmor = isArmor;
