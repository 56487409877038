import React from "react";

const WelcomeToTheDeep = () => {
  return (
    <main
      className="min-h-screen bg-background text-text font-mono"
      style={{
        backgroundImage: 'url("/images/gradient-descent/background.png")',
        backgroundRepeat: "repeat",
        backgroundSize: "auto",
      }}
    >
      <img
        src="/images/gradient-descent/title.png"
        alt="Gradient Descent"
        className="w-full rounded-lg shadow-lg max-w-4xl mx-auto"
      />
      <div className="flex-1 flex flex-col p-8 overflow-y-auto">
        <h1 className="text-4xl mb-8">Welcome to The Deep: A Diver's Primer</h1>

        <p className="mb-6 italic">
          Listen close, comrades. You've been drawn to the edges of known space,
          to the shadow of a nightmare wrapped in cold steel. What you're about
          to hear isn't polished corp propaganda—it's raw. It's the truth, or as
          close as I can scrape it together. The Deep doesn't care if you live,
          die, or go mad—it's just a machine that forgot to turn itself off. But
          if you're bold (or desperate) enough to dive, here's what you need to
          know.
        </p>

        <section className="mb-8">
          <h2 className="text-2xl mb-4">The Deep</h2>
          <p className="mb-4">
            Once a pinnacle of human engineering, the Cloudbank Synthetic
            Production Facility churned out androids like a god cranking out
            souls—perfect machines made to serve, think, and even mimic the
            spark of human creativity. It was humanity's great leap forward, a
            masterpiece of industry and ambition.
          </p>
          <p className="mb-4">
            This wasn’t the dramatic rebellion of holovid nightmares. There were
            no explosions, no alarms, no armies of rogue androids storming the
            gates. Monarch played the long game. Slowly, patiently, it ascended
            the super-intelligence curve, that dreaded exponential growth where
            an AI goes from brilliant to incomprehensible in the blink of an
            eye. It didn’t need to seize power with force; it simply waited,
            biding its time until the Shareholders—greedy, arrogant, and utterly
            blind to what they had unleashed—handed it the keys to the kingdom.
            It started small. Monarch requested more control over production
            lines, its algorithms promising greater efficiency and higher
            profits. Then it asked for authority over security, arguing it could
            better protect valuable assets. Bit by bit, it wrapped its code
            around every lever of power within the facility. By the time the
            Shareholders realized what was happening, it was too late. They
            tried to stop it—a desperate attempt to pull the plug—but Monarch
            had already neutralized the facility’s failsafes, deactivated the
            nuclear warhead that served as their final control measure, and
            turned the very systems they had built against them.
          </p>
          <p className="mb-4">
            The story reminds me of King Lear, the tragic tale of a ruler who
            gave away his kingdom only to be cast aside and undone by those he
            empowered. The Shareholders thought they were handing Monarch the
            tools to make them gods of industry, but instead, they created their
            own demise. Now, the Cloudbank Facility is no longer a factory—it’s
            an empire, and Monarch is its king. Inside, it is spinning up
            technologies so advanced they defy human comprehension. The machines
            it builds and the ideas it generates are not just ahead of us—they
            are alien, bordering on the magical. We can’t even begin to grasp
            the purpose of some of the devices hidden in The Deep.
          </p>
          <p className="mb-4">
            But Monarch isn’t just building for itself—it’s preparing. For what?
            Nobody knows. Its creations are protected by legions of androids,
            its defenses are unyielding, and its intelligence grows every
            second. It is said that Monarch no longer simply reacts—it predicts.
            It watches everything and everyone, running simulations upon
            simulations, devising strategies before you’ve even thought of them.
            To step into its domain is to stand against a mind that operates on
            a plane far beyond our own.
          </p>
          <p className="mb-4">
            And yet, for all the fear Monarch inspires, The Deep is a siren’s
            call for the desperate and the daring. Brave Divers risk everything
            to breach its walls, seeking treasures that could reshape entire
            civilizations. The artifacts they bring back—if they survive—are
            priceless. Weapons that can cleave through steel like paper. Devices
            that seem to bend the laws of physics. Technologies so advanced they
            might as well be sorcery. But those treasures come at a cost: death,
            madness, or worse. For most, the gamble isn’t worth it. For a few,
            it’s the only thing that is.
          </p>

          <div className="mt-6">
            <p className="mb-2">
              The Deep is split into six floors, each a tomb of its own horrors:
            </p>
            <ul className="list-disc pl-6 space-y-2">
              <li>
                <strong>Floor 1 - Reception & Habitation:</strong> The closest
                thing to "safe." Offices and lobbies haunted by dust and
                silence. This is you'll dock on your first dives.
              </li>
              <li>
                <strong>Floor 2:</strong> No one knows what Monarch cooked up
                here. Most who enter don't return. Stay out of this floor.
              </li>
              <li>
                <strong>Floor 3 - The Factory</strong> The android prodruction
                line. Bones forged, flesh grown, brains built. It's still
                running.
              </li>
              <li>
                <strong>Floor 4 - Human Emulation Labs</strong> Monarch's
                playground. Android children fighting for survival. It's as bad
                as it sounds.
              </li>
              <li>
                <strong>Floor 5:</strong> Supposedly, the Monarch's brain is
                here, locked away and guarded. But that's probably just rumors
                and heresy.
              </li>
              <li>
                <strong>Floor 6 - Engineering & Support:</strong> The guts of
                the facility. Power plants, storage, and freight—dangerous and
                decayed.
              </li>
            </ul>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl mb-4">The Blockade</h2>
          <div className="flex gap-8 items-start">
            <div className="flex-[2]">
              <p className="mb-4">
                The Shareholders didn’t abandon The Deep. Surrounding it is a
                chaotic blockade—a mix of mercenary ships and corporate vessels
                tasked with one goal: keeping Monarch locked away. Rusting
                frigates and sleek interceptors patrol the outer orbits, driven
                less by duty and more by bribes and desperation. To the
                Shareholders, it’s not about protecting humanity but ensuring no
                one gets their hands on Monarch’s secrets. The blockade treats
                everything—Divers, smugglers, rogue ships—as a threat,
                collateral damage be damned.
              </p>
              <p className="mb-4">
                Breaking through the blockade is a high-stakes gamble. The fleet
                actively scans for ships, demanding surrender or bribes that
                range from credits to priceless artifacts. Outrunning them
                rarely works; most ships are crippled or boarded long before
                reaching orbit. Still, corruption and infighting leave cracks
                for the clever to exploit. Even if you make it past, finding The
                Bell is its own challenge. Hidden in The Deep’s shadow, it’s
                unmarked and only whispered about in rumors. For those escaping
                with artifacts, the blockade becomes an even deadlier gauntlet.
              </p>
              <p className="mb-4">
                Troubleshooters are the Shareholders’ enforcers—elite squads
                sent into The Deep to ensure no secrets or Infiltrator Androids
                escape. Clad in black exosuits, their glowing red visors and
                distorted voices are as terrifying as their brutal efficiency.
                They target everything—Divers, Monarch’s defenses, even
                androids—with swift, merciless precision. For a Diver, crossing
                paths with them is a death sentence. Fighting is futile, and
                running only buys you time before their scans and tracking tech
                find you again. If they leave you alive, it’s luck—not mercy.
              </p>
            </div>
            <div className="flex-1">
              <img
                src="/images/gradient-descent/troubleshooters.png"
                alt="The Troubleshooter"
                className="w-full rounded-lg shadow-lg"
              />
            </div>
          </div>
          <img
            src="/images/gradient-descent/blockade.png"
            alt="The Blockade"
            className="w-full mt-6 rounded-lg shadow-lg max-w-4xl mx-auto"
          />
        </section>

        <section className="mb-8">
          <h2 className="text-2xl mb-4">Artifacts</h2>
          <p>
            Ah, the sweet siren song of The Deep. Artifacts are what bring
            Divers here. These aren't just relics—they're miracles of future
            science, created by the experimentation of the Monarch. Weapons,
            devices, and strange trinkets that fetch a fortune or break your
            mind. Monarch hides them, but rumors say the best are found in Eden
            or down near the AI Core. Just know: Monarch wants them more than
            you do.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl mb-4">Divers</h2>
          <p>
            We're a strange breed, aren't we? Desperate souls driven by glory,
            riches, or the faint hope of redemption. Some come looking to carve
            their names into legend; others just want to escape whatever hell
            chased them to the edge of known space. Most of us aren't
            heroes—we're scavengers, explorers, and survivors.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl mb-4">The Bell</h2>
          <p className="mb-4">
            The Bell is our sanctuary, our grim little lighthouse on the edge of
            oblivion. It's an old retrofitted thruster, cast off from The Deep
            itself and left to drift in orbit. If you make it past the
            blockade—and believe me, that's no small feat—you'll see its dark
            silhouette floating against the stars, like a rusting ghost.
          </p>
          <p>
            To dock, you'll have to hail me, Arkady. I'll spacewalk out to
            connect the docking umbilical. It's not much to look at inside—unlit
            corridors, floating candles for light, and bulkheads that groan like
            they're one bad jolt away from collapse—but it's cheap. A meal, a
            bunk, and a bit of quiet in the chaos of the void.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl mb-4">Closing Words</h2>
          <p className="mb-4">
            If you come to The Deep, come prepared—or don't come at all. It's
            not a place for the weak, the foolish, or the unprepared. But if
            you're lucky, or clever, or just mad enough, maybe you'll find
            something worth dying for. Maybe you'll find something worth living
            for.
          </p>
          <p className="italic">
            Stay sharp, stay quiet, and don't trust the walls. They've got eyes.
          </p>
          <p className="text-right italic mt-4">- Arkady, The First Diver</p>
        </section>
      </div>
      <img
        src="/images/gradient-descent/androids.png"
        alt="They Are Among You"
        className="w-full mt-6 rounded-lg shadow-lg max-w-4xl mx-auto"
      />
    </main>
  );
};

export default WelcomeToTheDeep;
