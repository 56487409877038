import { Creature } from "@ai-dm/utils";

export const GOBLIN: Creature = {
  creatureName: "Goblin",
  name: "Zig",
  shortDescription:
    "A simple goblin bandit. He wants gold, food, and to survive.",
  description: "A simple goblin bandit. He wants gold, food, and to survive.",
  info: `Goblins are small, black-hearted, selfish humanoids that lair in caves, abandoned mines, despoiled dungeons, and other dismal settings. Individually weak, goblins gather in large-sometimes overwhelming-numbers. They crave power and regularly abuse whatever authority they obtain.

Goblinoids. Goblins belong to a family of creatures called goblinoids. Their larger cousins, hobgoblins and bugbears, like to bully goblins into submission. Goblins are lazy and undisciplined, making them poor servants, laborers, and guards.

Malicious Glee. Motivated by greed and malice, goblins can't help but celebrate the few times they have the upper hand. They dance, caper with sheer joy when victory is theirs. Once their revels have ended, goblins delight in the torment of other creatures and embrace all manner of wickedness.

Leaders and Followers. Goblins are ruled by the strongest or smartest among them. A goblin boss might command a single lair, while a goblin king or queen (who is nothing more than a glorified goblin boss) rules hundreds of goblins, spread out among multiple lairs to ensure the tribe's survival. Goblin bosses are easily ousted, and many goblin tribes are taken over by hobgoblin warlords or bugbear chiefs.

Challenging Lairs. Goblins festoon their lairs with alarms designed to signal the arrival of intruders. Those lairs are also riddled with narrow tunnels and bolt-holes that human-sized creatures can't navigate, but which goblins can crawl through with ease, allowing them to flee or to circle around and surprise their enemies. Rat Keepers and Wolf Riders. Goblins have an affinity for rats and wolves, raising them to serve as companions and mounts, respectively. Like rats, goblins shun sunlight and sleep underground during the day. Like wolves, they are pack hunters, made bolder by their numbers. When they hunt from the backs of wolves, goblins use hit-and-run attacks.

Worshipers of Maglubiyet. Maglubiyet the Mighty One, the Lord of Depths and Darkness, is the greater god of goblinoids. Envisioned by most goblins as an eleven-foot-tall battle-scarred goblin with black skin and fire erupting from his eyes, he is worshiped not out of adoration but fear. Goblins believe that when they die in battle, their spirits join the ranks of Maglubiyet's army on the plane of Acheron. This is a "privilege" that most goblins dread, fearing the Mighty One's eternal tyranny even more than death.`,
  hp: 7,
  maxhp: 7,
  ac: 14,
  size: "small",
  stats: {
    str: -2,
    dex: 2,
    con: 0,
    cha: -2,
    wis: -2,
  },
  skills: ["stealth"],
  senses: ["darkvision"],
  languages: ["Common", "Goblin"],
  cr: 0.25,
  abilities: [],
  actions: [
    {
      name: "Scimitar Attack",
      description: "The goblin slashes with its scimitar",
      range: "Near",
      abilityCheck: {
        stat: ["dex"],
      },
      damage: [{ sides: 6 }, 2],
    },
    {
      name: "Shortbow Attack",
      description: "The goblin shoots an arrow from its shortbow from afar",
      range: "Far",
      abilityCheck: {
        stat: ["dex"],
      },
      damage: [{ sides: 6 }, 2],
    },
  ],
  conditions: [],
};

export const HUMAN_COMMONER: Creature = {
  creatureName: "Commoner",
  name: "Abigail",
  shortDescription:
    "The daughter of a nearby tanner. She wants to find a husband, and is afraid of the dark woods.",
  description:
    "The daughter of a nearby tanner. She wants to find a husband, and is afraid of the dark woods.",
  info: "Commoners include peasants, serfs, slaves, servants, pilgrims, merchants, artisans, and hermits.",
  hp: 4,
  maxhp: 4,
  ac: 10,
  size: "medium",
  stats: {
    str: 0,
    dex: 0,
    con: 0,
    cha: 0,
    wis: 0,
  },
  skills: [],
  senses: [],
  languages: ["Common"],
  cr: 0,
  abilities: [],
  actions: [
    {
      name: "Unarmed Strike",
      description: "Brawling with their fists",
      range: "Close",
      abilityCheck: {
        stat: ["str"],
      },
      damage: [1],
    },
  ],
  conditions: [],
};
